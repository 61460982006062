<template>
  <section class="panel">
    <ImageGallery
      :images="form.post_images"
      :index="index"
      :key="form.post_id"
      :disableScroll="true"
      :glideParams="this.galleryGlideQueryString"
      @close="index = null" />

    <div id="inset-content">
      <div id="post-form-wrapper" v-if="validToken">
        <form id="post-form" @submit.prevent="submitPostForm()">
          <h1 v-if="this.isEditing">Edit Latest News Post</h1>
          <h1 v-else>Add Latest News Post</h1>
          <div class="equalHMVWrap eqWrap">
            <div class="equalHMV">
              <div class="row">
                <label class="control-label">ID:</label>
                <div class="formfield">
                  <input
                    id="postId"
                    v-model="form.post_id"
                    class="textinput"
                    form="post-form"
                    name="post-id"
                    required
                    type="text"
                    placeholder="0" />
                </div>
              </div>
              <div class="row">
                <label class="control-label">Date:</label>
                <div class="formfield">
                  <input
                    id="post-date"
                    type="date"
                    name="postDate"
                    required
                    form="post-form"
                    v-model="form.post_date" />
                </div>
              </div>
              <div class="row">
                <label class="control-label">Title:</label>
                <div class="formfield">
                  <input
                    id="title"
                    v-model="form.title"
                    class="textinput"
                    form="post-form"
                    name="title"
                    required
                    type="text"
                    placeholder="Title of the post" />
                </div>
              </div>
              <div class="content-row">
                <label class="control-label">Content:</label>
                <tinymce-editor
                  id="post-body"
                  v-model="form.post_body"
                  name="post_body"
                  class="textinput"
                  placeholder="A news item..."
                  :init="this.tinyMCEInit" />
              </div>
              <div
                id="post-image-wrapper"
                class="row"
                @drop="processDroppedFiles"
                @dragenter.prevent
                @dragover.prevent>
                <div
                  v-for="(thumb, thumbIndex) in form.post_images_thumbs"
                  :key="thumbIndex"
                  class="post-image"
                  @click="index = thumbIndex">
                  <img class="post-image-thumb" :src="thumb" />
                  <button
                    class="delete_button"
                    @click.prevent.stop="deleteImage(thumbIndex)">
                    X
                  </button>
                </div>
                <div
                  class="post-image"
                  v-for="(fileName, fileNameIndex) in post_images_to_upload"
                  :key="fileNameIndex">
                  <img class="post-image-thumb" :src="fileName.image" />
                </div>
              </div>
              <div id="controls">
                <label for="file-upload" class="custom-file-upload">
                  add images
                </label>
                <input
                  form="post-form"
                  id="file-upload"
                  ref="file"
                  type="file"
                  accept="image/*"
                  multiple="multiple"
                  @change="setPhotoFiles($event.target.files)" />
                <button
                  v-if="!this.isEditing"
                  class="button"
                  id="clear-input-files"
                  name="clearInputFiles"
                  @click.prevent="clearAddedPhotoFiles()">
                  clear file list
                </button>
                <button
                  id="submit-post-form"
                  class="button"
                  name="submitNewPostForm"
                  type="submit"
                  form="post-form"
                  value="Save">
                  save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div v-else-if="!validToken" class="unauthorised">Unauthorised</div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import { useToast } from 'vue-toastification'
import ImageGallery from '@/components/Latest News/ImageGallery'
import { ScrollTrigger, gsap } from 'gsap/all'
import Editor from '@tinymce/tinymce-vue'
import contentUiCss from 'tinymce/skins/ui/oxide/content.css'
import 'tinymce/tinymce.min'
import 'tinymce/themes/silver/theme.min'
import 'tinymce/icons/default/icons.min'
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/code'
import 'tinymce/plugins/emoticons'
import 'tinymce/plugins/emoticons/js/emojis'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/table'
export default {
  props: {
    id: {
      type: Number,
    },
  },
  components: {
    'tinymce-editor': Editor,
    ImageGallery,
  },
  name: 'AddEditPostItem',
  setup() {
    // Setup Toast notifcations
    const toast = useToast()
    return { toast }
  },
  data() {
    return {
      tinyMCEInit: {
        content_style: contentUiCss.toString(),
        branding: false,
        inline: true,
        plugins: 'advlist code emoticons link lists table',
        autoresize_overflow_padding: 50,
        menubar: false,
        toolbar:
          'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
        quickbars_selection_toolbar:
          'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
        toolbar_mode: 'sliding',
        contextmenu: 'link image imagetools table configurepermanentpen',
        toolbar_location: 'bottom',
      },

      editID: this.id,
      isEditing: false,
      posts: [],
      form: {
        title: '',
        post_id: '',
        post_date: '',
        post_images: [],
        post_images_thumbs: [],
        post_body: '',
      },
      postFormTL: null,
      index: null,
      glideQueryString: 'w=75&h=75&fit=crop',
      galleryGlideQueryString: 'w=2160&h=1440',
      post_images_to_upload: [],
    }
  },
  computed: {
    validToken() {
      if (this.$store.state.token) {
        return true
      } else {
        return false
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // access to component public instance via `vm`
      if (!vm.validToken) {
        vm.$router.push({ path: '/admin/login' })
      }
    })
  },
  async mounted() {
    if (this.validToken) {
      if (this.$route.params.id) {
        this.isEditing = true
        this.editID = this.$route.params.id
      }
      if (!this.isEditing) {
        await this.getLastIDNumber().then((response) => {
          this.form.post_id = response
        })
      } else {
        this.fetchPostToEdit(this.editID)
      }
      gsap.registerPlugin(ScrollTrigger)
      this.form.post_date = this.todaysDateForInputDateField()
      this.$nextTick(() => {
        this.postFormTL = gsap
          .timeline({ delay: 0.8 })
          .set('#post-form > H1', { autoAlpha: 0 })
          .fromTo(
            '#post-form > H1',
            { autoAlpha: 0, x: -25, y: -25 },
            { autoAlpha: 1, x: 0, y: 0, duration: 0.3 }
          )
          .fromTo(
            '.equalHMV *',
            { autoAlpha: 0, x: -25, y: -25 },
            { autoAlpha: 1, x: 0, y: 0, stagger: 0.03 },
            '-=0.3'
          )
      })
    }
  },
  unmounted() {
    if (this.postFormTL) {
      this.postFormTL.reverse()
      this.postFormTL.kill()
    }
  },
  methods: {
    clearAddedPhotoFiles() {
      document.getElementById('file-upload').value = null
      this.post_images_to_upload = []
    },
    deleteImage(thumbIndex) {
      let deletePostImageURL =
        process.env.VUE_APP_ROOT_API + 'latest-news/delete_post_image.php'

      const jsonToSend = JSON.stringify({
        post_id: this.form.post_id,
        image_id: thumbIndex,
      })

      axios
        .post(
          deletePostImageURL,
          jsonToSend, // the data to post
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.token}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then(async (response) => {
          if (response.data === 'Post image successfully deleted.') {
            this.form.post_images.splice(thumbIndex, 1)
            this.toast.success(response.data)
          } else if (
            response.data === 'Incorrect content given for deletion of image.'
          ) {
            this.toast(this.strip(response.data).trim(), {
              type: 'error',
              timeout: 60000,
            })
          } else {
            this.toast(this.strip(response.data).trim(), {
              type: 'error',
              timeout: 60000,
            })
          }
        })
        .catch((error) => {
          this.toast.error(
            `Error when trying to delete image from the server: The error was "${error}"`,
            { timeout: 30000 }
          )
        })
    },
    async getLastIDNumber() {
      // Connect to database to retrieve posts to calculate the last ID number to increase by 1 for new post.

      let databaseFile = process.env.VUE_APP_ROOT_API + 'latest-news/read.php'

      const promise = axios
        .get(databaseFile, {
          responseType: 'json',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .catch((error) => {
          if (error.message == 'Network Error') {
            this.toast.error(
              `Could not connect to database to retrieve ID using URL ${error.config.url}`
            )
          } else if (error.response.data.message === 'No record found.') {
            this.toast.info(`No posts found so creating a new post`)
            this.form.post_id = 1
          }
        })
      const postId = await promise.then((response) => {
        try {
          return response.data.itemCount + 1
        } catch {
          // No posts available so just return id of 1.
          return 1
        }
      })
      return postId
    },
    todaysDateForInputDateField() {
      var date = new Date()
      var day = date.getDate()
      var month = date.getMonth() + 1
      var year = date.getFullYear()

      if (month < 10) month = '0' + month
      if (day < 10) day = '0' + day

      var today = year + '-' + month + '-' + day
      return today
    },
    async fetchPostToEdit(id) {
      let self = this
      let form = this.form
      let databaseFile =
        process.env.VUE_APP_ROOT_API + 'latest-news/single_read.php?id=' + id
      await axios
        .get(databaseFile)
        .then(async (response) => {
          form.post_id = response.data.id
          form.title = response.data.title
          let databaseDate = response.data.post_date
          form.post_date = self.timestampToFormDateFieldValue(databaseDate)
          form.post_body = response.data.body
          form.post_images = response.data.post_images
          form.post_images_thumbs = response.data.post_images

          ///// GALLERY IMAGES
          if (form.post_images != '') {
            const imageTokensPromises = form.post_images.map(
              async (imageWithoutToken) => {
                return await this.getImageTokenForImage(
                  imageWithoutToken,
                  this.galleryGlideQueryString
                )
              }
            )

            const imagesWithTokens = await Promise.all(imageTokensPromises)

            const formImages = imagesWithTokens.map((image) => {
              return image.data
            })

            this.form.post_images = formImages
          } else {
            form.post_images = []
          }

          if (form.post_images_thumbs != '') {
            //// THUMB IMAGES
            const thumbImagePromises = form.post_images_thumbs.map(
              async (image) => {
                return await this.getImageTokenForImage(
                  image,
                  this.glideQueryString
                )
              }
            )

            const thumbImagesWithTokens = await Promise.all(thumbImagePromises)

            const formThumbImages = thumbImagesWithTokens.map(
              (image, imageIndex) => {
                return image.data
              }
            )

            this.form.post_images_thumbs = formThumbImages
          } else {
            form.post_images_thumbs = []
          }
        })
        .catch((error) => {
          if (error.response !== undefined) {
            if (error.response.data === 'Post not found.') {
              this.toast.error(error.response.data)
              let mainContent = document.getElementById('post-form')
              mainContent.innerHTML = `<div style="text-align: center;  font-size: 2.5vmin; color: #ff001b ">
        There was a problem retrieving that post ID.<br>
        </div>`
            }
          } else {
            this.toast.error(`${error}`, { timeout: 30000 })
          }
        })
    },
    async getImageTokenForImage(image, glideParams) {
      let retrieveImageTokenURL =
        process.env.VUE_APP_ROOT_API + 'admin/get_image_token.php'
      return axios.post(retrieveImageTokenURL, {
        image: image,
        queryParams: glideParams,
      })
    },
    async submitPostForm() {
      if (!this.isEditing) {
        let addLatestPostUrl =
          process.env.VUE_APP_ROOT_API + 'latest-news/create.php'

        let formData = new FormData()

        this.$refs.file.files.forEach((file, i) => {
          formData.append('files[' + i + ']', file)
        })
        this.post_images_to_upload.forEach((file, i) => {
          formData.append('files[' + i + ']', file.file)
        })
        formData.append('post_id', this.form.post_id)
        formData.append('title', this.form.title)
        formData.append('post_date', this.form.post_date)
        formData.append('post_body', this.form.post_body)
        formData.append('post_images', JSON.stringify(this.form.post_images))

        if (this.validToken) {
          axios
            .post(
              addLatestPostUrl,
              formData, // the data to post
              {
                headers: {
                  Authorization: `Bearer ${this.$store.state.token}`,
                },
              }
            )
            .then(async (response) => {
              if (response.data === 'Post successfully added.') {
                this.toast.success(response.data)
                this.form.post_id = this.form.post_id + 1
              } else {
                this.toast(this.strip(response.data).trim(), {
                  type: 'error',
                  timeout: 60000,
                })
              }
              return
            })
            .catch((error) => {
              this.toast.error(
                `Error when trying to submit the new post: The error was "${error}"`,
                { timeout: 30000 }
              )
            })
        }
      } else {
        let editLatestPostUrl =
          process.env.VUE_APP_ROOT_API + 'latest-news/update.php'

        let formData = new FormData()

        this.$refs.file.files.forEach((file, i) => {
          formData.append('files[' + i + ']', file)
        })
        this.post_images_to_upload.forEach((file, i) => {
          formData.append('files[' + i + ']', file.file)
        })
        formData.append('post_id', this.form.post_id)
        formData.append('title', this.form.title)
        formData.append('post_date', this.form.post_date)
        formData.append('post_body', this.form.post_body)
        formData.append('post_images', JSON.stringify(this.form.post_images))

        axios
          .post(
            editLatestPostUrl,
            formData, // the data to post
            {
              headers: {
                Authorization: `Bearer ${this.$store.state.token}`,
              },
            }
          )
          .then(async (response) => {
            if (response.data === 'Post successfully edited.') {
              this.toast.success(response.data)
            } else {
              this.toast(this.strip(response.data).trim(), {
                type: 'error',
                timeout: 60000,
              })
            }
            return
          })
          .catch((error) => {
            this.toast.error(
              `Error when trying to submit the post edits: The error was "${error}"`,
              { timeout: 30000 }
            )
          })
      }
    },
    timestampToFormDateFieldValue(timestamp) {
      return new Date(timestamp.replace(/ /g, 'T'))
        .toISOString()
        .split(/[T ]/i, 1)[0]
    },
    strip(html) {
      let string = html.replace(/<br ?\/?>/gi, '\n')
      let doc = new DOMParser().parseFromString(string, 'text/html')
      return doc.body.textContent || ''
    },
    setPhotoFiles(files) {
      files.forEach((file) => {
        const fileToUpload = new Object()
        fileToUpload.image = URL.createObjectURL(file)
        fileToUpload.file = file
        this.post_images_to_upload.push(fileToUpload)
      })
    },
    processDroppedFiles(e) {
      e.preventDefault()
      let droppedFiles = e.dataTransfer.files
      droppedFiles.forEach((file) => {
        const fileToUpload = new Object()
        fileToUpload.image = URL.createObjectURL(file)
        fileToUpload.file = file
        this.post_images_to_upload.push(fileToUpload)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~tinymce/skins/ui/oxide/skin.min.css';
@import '~tinymce/skins/ui/oxide/content.min.css';

#inset-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

#post-form-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1440px;
}

/* Contact form */

#post-form {
  font-size: 1.5vh;
  max-height: 85vh;
}

.has-error {
  color: crimson;
}

#post-form {
  overflow: scroll;
  width: 75vw !important;

  @media #{$mq-tiny} {
    width: 80vw !important;
  }
}

#post-form > div > div > div input {
  -webkit-appearance: none;
  appearance: none;
  border: 1px rgb(131, 8, 8);
  margin: 7px 0px 15px;
  padding: 5px;
  flex-wrap: wrap;
  width: 100%;
}

.mce-edit-focus {
  outline: 1px solid $makeitsopurple !important;
}

.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
  color: rgba(198, 198, 198, 0.7);
}
#post-body {
  width: 100%;
  max-width: 100%;
  min-height: 6.56vh;
  margin-left: 1px;
}

input[type='file'] {
  display: none;
}
.custom-file-upload {
  background-color: $makeitsopurple;
  color: white;
  display: inline-block;
  padding: 10px 32px;
  text-decoration: none;
  cursor: pointer;
  font-size: 10pt;
  line-height: normal;
  margin-right: 4px;
  &:focus {
    outline: 1px solid $makeitsopurplelight;
  }

  &:hover {
    background-color: $makeitsopurplehover;
    font-weight: bold;
  }
}

#file-upload:focus {
  outline: 1px solid $makeitsopurplehover;
}

.col {
  flex: 1;
}

.flex-grid {
  display: block;
}

.eqWrap {
  display: flex;
}

.equalHMVWrap {
  flex-wrap: wrap;
}

.equalHMV {
  width: 100%;
  padding-right: 5px;
}

.equalHMV:nth-child(2) {
  padding-right: 0px;
  padding-left: 5px;
}

.delete_button {
  position: absolute;
  top: 0;
  right: 0;
  width: 25px;
  height: 25px;
  background-color: $makeitsopurple;
  border: 1px solid transparent;
  padding: 0px;
  margin: 0px;
  color: whitesmoke;

  &:focus {
    outline: 1px solid $makeitsopurplelight;
  }

  &:hover {
    background-color: $makeitsopurplehover;
    font-weight: bold;
  }
}

#post-image-wrapper {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(75px, auto));
  -moz-column-gap: 0.7em;
  column-gap: 0.7em;
  row-gap: 0.7em;
  grid-column: 1;
  justify-content: start;
  background-color: rgb(22, 22, 22);

  @media #{mq-tiny-small} {
    column-gap: 2.5em;
  }
  @media #{mq-tiny} {
  }
  @media #{mq-small} {
  }
  @media #{mq-medium} {
  }
  @media #{mq-large} {
  }
  @media screen and (orientation: landscape) {
  }
}
.post-image {
  width: 125px;
  height: 125px;
  display: inline-block;
  position: relative;
}

.post-image img {
  object-fit: cover;
  width: 125px;
  height: 125px;
}

.post-image-thumb {
  display: block;
  width: 100%;
  height: auto;

  &:hover {
    cursor: pointer;
  }
}

#controls {
  margin: 1em 0em;
  padding: 0px;
  box-sizing: border-box;
}
</style>
